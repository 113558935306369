<footer class="footer">
    <div class="main">
        <button mat-button
                class="back-to-top btn-common"
                (click)="goToTop()">
            <mat-icon>arrow_drop_up</mat-icon>
            {{'App.Footer.BackToTopButton' | translate}}
        </button>
        <p *ngIf="text" class="text" [innerHTML]="text | translate"></p>
    </div>

    <div class="contacts">
        <div translate class="contacts__label">App.Footer.Contacts</div>
        <div *ngIf="email">
            <div translate class="contacts__item-label">App.Footer.Email</div>
            <a target="_blank" class="contacts__item" href="tel:{{email}}">{{email}}</a>
        </div>
        <div *ngIf="phone">
            <div translate class="contacts__item-label">App.Footer.Phone</div>
            <a target="_blank" class="contacts__item" href="mailto:{{phone}}">{{phone}}</a>
        </div>
    </div>
</footer>
