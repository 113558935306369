<footer class="footer">
    <div class="actions">
        <div class="actions__wrapper">
            <button mat-button
                    class="footer-btn button button_small"
                    data-test-id="scroll-to-top"
                    (click)="goToTop()">
                <mat-icon>arrow_drop_up</mat-icon>
                {{'App.Footer.BackToTopButton' | translate}}
            </button>
            <app-navigation></app-navigation>
            <a mat-button
               target="_blank"
               href="privacy_policy.pdf"
               class="footer-btn button button_small">
                {{'App.Footer.PrivacyPolicy' | translate}}
            </a>
            <a mat-button
               *ngIf="isPediHCCTheme"
                class="footer-btn button button_small"
                (click)="openJoinMailingList()">
                {{'App.HomePage.StayInformedSection.JoinMailingListButton' | translate}}
            </a>
        </div>
        <div class="auth">
            <ddp-language-selector></ddp-language-selector>
            <app-auth [isInFooter]="true" [isPediHCCTheme]="isPediHCCTheme"></app-auth>
        </div>
    </div>
    <div class="info">
        <span>Copyright © {{ copyrightYear }} Count Me In. {{'App.Footer.Copyright' | translate}} </span>
        <div class="contacts">
            <span translate class="contacts__label">App.Footer.Contacts</span>
            <a target="_blank" class="contacts__item" href="mailto:{{email}}">{{email}}</a>
            <a target="_blank" class="contacts__item" href="tel:{{phone}}}">{{phone}}</a>
        </div>
    </div>
</footer>
