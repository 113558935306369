<div class="container">
    <img class="join-cmi-logo"
         lazy-resource
         src="assets/images/hands.png"
         [alt]="'App.HomePage.CountMeInSection.ImageAlt' | translate">
    <h2 class="title static-page-title">{{title | translate}}</h2>
    <p class="text static-page-paragraph-text">{{text | translate}}</p>
    <a mat-raised-button
       [routerLink]="'/' + AppRoutes.CountMeIn"
       queryParamsHandling="merge"
       class="btn join-cmi-btn  button button_small"
       [color]="isColorectalTheme ? 'colorectal' : isPediHCCTheme ? 'pedihcc' : 'primary'">
        {{btnText | translate}}
    </a>
</div>
