<div class="container">
    <div class="social-media__links">
        <a class="social-media__link"
           [href]="'App.social.twitter' | translate"
           target="_blank"
           (click)="sendSocialMediaAnalytics('twitter')">
            <img class="social-media__logo" lazy-resource [src]="isPedihcc ? 'assets/images/twitter_x.svg' : 'assets/images/twitter.svg'"
                 [alt]="'Common.Alts.Twitter' | translate">
        </a>
        <a class="social-media__link"
           [href]="'App.social.facebook' | translate"
           target="_blank"
           (click)="sendSocialMediaAnalytics('facebook')">
            <img class="social-media__logo" lazy-resource src="assets/images/facebook.svg"
                 [alt]="'Common.Alts.Facebook' | translate">
        </a>
        <a class="social-media__link" [href]="'App.social.instagram' | translate" 
            target="_blank"
            (click)="sendSocialMediaAnalytics('instagram')">
            <img class="social-media__logo" lazy-resource src="assets/images/instagram.svg"
                [alt]="'Common.Alts.Instagram' | translate">
        </a>
        <a class="social-media__link" [href]="'App.social.threads' | translate" 
            target="_blank" 
            *ngIf="isPedihcc"
            (click)="sendSocialMediaAnalytics('threads')">
            <img class="social-media__logo" lazy-resource src="assets/images/threads.svg"
                [alt]="'Common.Alts.Threads' | translate">
        </a>
        <a class="social-media__link"
           [href]="'App.social.linkedin' | translate"
           target="_blank"
           *ngIf="isPedihcc"
           (click)="sendSocialMediaAnalytics('linkedin')">
            <img class="social-media__logo" lazy-resource src="assets/images/linkedin.svg"
                 [alt]="'Common.Alts.LinkedIn' | translate">
        </a>
    </div>
    <h2 class="title static-page-title" translate>App.HomePage.StayInformedSection.Title</h2>
    <div class="text static-page-paragraph-text" translate>App.HomePage.StayInformedSection.Text</div>
    <button mat-flat-button
            class="btn join-btn small-letter-spacing button button_small"
            (click)="openJoinMailingList()">
        {{'App.HomePage.StayInformedSection.JoinMailingListButton' | translate}}
    </button>
</div>
