<div class="wrapper" [class.lms-page]="isLmsPage">
    <app-header *ngIf="!isLmsPage"
        [isColorectalTheme]="isColorectalPage"
        [isPediHCCTheme]="isPediHCCPage"
    ></app-header>
    <router-outlet></router-outlet>
    <app-footer *ngIf="!isSplashPage"
        [isPediHCCTheme]="isPediHCCPage"
        [phone]="phone"
        [email]="email">
    </app-footer>
</div>
