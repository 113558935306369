export const AppRoutes = {
    Activity: 'activity',
    ActivityId: 'activity/:id',
    ActivityLinkId: 'activity-link/:id',
    AgeUpAccept: 'accept',
    AgeUpVerify: 'verify',
    AgeUpThankYouProxy: 'proxy-thank-you',
    AgeUpThankYouVerify: 'thank-you',
    Dashboard: 'dashboard',
    Error: 'error',
    LoginLanding: 'login-landing',
    LoginLandingMode: 'login-landing/:mode',
    LocalAuth: 'auth',
    Password: 'password',
    SessionExpired: 'session-expired',
    PasswordResetDone: 'password-reset-done',
    CountMeIn: 'count-me-in',
    AboutUs: 'about-us',
    Participation: 'participation',
    ScientificResearch: 'scientific-research',
    FAQ: 'faq',
    JoinList: 'join-list',
    ColorectalPage: 'colorectal',
    PediHCCPage: 'pedihcc',
    LMS: 'lms'
};
